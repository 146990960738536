import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import styles from './styles.module.css';

class Lightbox extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      mainSrc: props.mainSrc,
      nextSrc: props.nextSrc,
      prevSrc: props.prevSrc,
      visible: props.visible,
      moving: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.animationStopping = this.animationStopping.bind(this);

  }

  componentWillReceiveProps(nextProps) {

    let moving = false;

    if (this.state.visible && nextProps.visible && this.state.nextSrc === nextProps.mainSrc) {

      moving = 'forwards';

    } else if (this.state.visible && nextProps.visible &&
      this.state.prevSrc === nextProps.mainSrc) {

      moving = 'backwards';

    }

    if (nextProps !== this.props) {

      this.setState({
        mainSrc: nextProps.mainSrc,
        nextSrc: nextProps.nextSrc,
        prevSrc: nextProps.prevSrc,
        visible: nextProps.visible,
        moving,
      });

    }

  }

  handleClose(e) {

    e.stopPropagation();
    e.preventDefault();
    this.props.onCloseRequest();

  }

  animationStopping() {

    this.setState({
      moving: false,
    });

  }

  componentDidUpdate() {

    let elem = null;
    let lbStyle = '';
    /* eslint-disable prefer-destructuring */
    let mainSrc = this.state.mainSrc;
    let nextSrc = this.state.nextSrc;
    let prevSrc = this.state.prevSrc;
    /* eslint-enable prefer-destructuring */

    if (this.state.moving === 'forwards') {

      lbStyle = styles.movingForwards;
      nextSrc = this.state.mainSrc;
      mainSrc = this.state.prevSrc;

    } else if (this.state.moving === 'backwards') {

      lbStyle = styles.movingBackwards;
      prevSrc = this.state.mainSrc;
      mainSrc = this.state.nextSrc;

    }

    if (this.state.visible) {

      elem = (
        <div className={styles.lbContainerOuter}>
          <div
            className={styles.lbContainerInner}
            onClick={this.handleClose}
          >
            <img
              src={mainSrc}
              className={`${styles.lbImage} ${lbStyle}`}
              alt="lightboxImage"
              onClick={this.handleClose}
            />
            {this.state.moving === 'forwards' &&
            <img
              src={nextSrc}
              className={`${styles.lbOtherImage} ${styles.movingForwardsOther}`}
              alt="lightboxImage"
              onAnimationEnd={this.animationStopping}
            />
            }
            {this.state.moving === 'backwards' &&
            <img
              src={prevSrc}
              className={`${styles.lbOtherImage} ${styles.movingBackwardsOther}`}
              alt="lightboxImage"
              onAnimationEnd={this.animationStopping}
            />
            }
          </div>
          <div className={styles.prevButton} id="prevButton" onClick={this.state.moving ? null : this.props.onMovePrevRequest}>
            {<i className={`entypo icon-left-open ${styles.icon}`} />}
          </div>
          <div className={styles.nextButton} id="nextButton" onClick={this.state.moving ? null : this.props.onMoveNextRequest}>
            {<i className={`entypo icon-right-open ${styles.icon}`} />}
          </div>
        </div>);

    } else {

      elem = <div/>;

    }

    const modalRoot = document.getElementById('modal-root');
    if (modalRoot) ReactDOM.render(elem, modalRoot);

  }

  // eslint-disable-next-line class-methods-use-this
  render() {

    return null;

  }

}

Lightbox.propTypes = {
  mainSrc: PropTypes.string,
  nextSrc: PropTypes.string,
  prevSrc: PropTypes.string,
  onCloseRequest: PropTypes.func,
  onMovePrevRequest: PropTypes.func,
  onMoveNextRequest: PropTypes.func,
  visible: PropTypes.bool,
};

export default Lightbox;
