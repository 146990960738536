import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { constructLink } from '../../../../helper';
import Image from '../../../Image';

import styles from './styles.module.css';

class SectionMedia extends React.Component {

  constructor(props) {

    super(props);

    const {
      link, type,
    } = constructLink(props.data.linkObj, props.pagePathList,
      props.articlePathList, props.filePathList);
    this.state = {
      type,
      link,
    };

  }

  render() {

    const iconStyle = this.props.iconStyle || '';
    const align = styles.alignCenter;
    const id = this.props && this.props.data ? this.props.data.id : undefined;

    const isLink = this.state.link !== undefined;
    const isLocal = isLink && this.state.type !== 'EXTERNAL' && this.state.type !== 'FILE';
    let previewTarget;
    if (!isLocal) previewTarget = this.state.link && this.state.link.startsWith('http') ? '_blank' : '_self';
    else {

      if (typeof this.props.data.linkObj === 'object') previewTarget = this.props.data.linkObj.openLinkInNewTab ? '_blank' : '_self';
      else previewTarget = this.props.data.openLinkInNewTab ? '_blank' : '_self';

    }

    const mediaElement = (this.props.mediaType === 'IMAGE')
      ? (
        <Image
          alt={this.props.alt}
          id={id}
          url={this.props.src}
          imageClass={this.props.elementStyle}
          sizes={this.props.sizes || '100vw'}
          onClick={this.props.onClick}
          images={this.props.images}
          transform={this.props.data ? this.props.data.transform : undefined}
          useTransforms={this.props.useTransforms}
        />
      )
      : (
        <div
          className={`${this.props.align === undefined ? align : styles[`align${this.props.align}`]} ${styles.iconWrapper} ${styles[iconStyle]}`}
        >
          <span
            className={`entypo ${this.props.data.icon.class}`}
            style={
              {
                fontSize: this.props.data.icon.size,
                color: this.props.data.icon.color,
              }
            }
          />
        </div>
      );

    return (
      <div
        className={`${styles.overflowHidden} ${this.props.align ? styles[`align${this.props.align}`] : align} ${this.props.wrapperStyle ? styles[this.props.wrapperStyle] : ''}`}
      >
        {
          isLink && isLocal && previewTarget !== '_blank'
            ? (
              <Link target={previewTarget} rel="noopener noreferrer" to={this.state.link}>
                {mediaElement}
              </Link>
            )
            : isLink && (!isLocal || previewTarget === '_blank')
              ? (
              <a target={previewTarget} rel="noopener noreferrer" href={this.state.link}>
                {mediaElement}
              </a>
              )
              : (mediaElement)
        }
      </div>
    );

  }

}

SectionMedia.propTypes = {
  align: PropTypes.string,
  mediaType: PropTypes.string,
  elementStyle: PropTypes.string,
  wrapperStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  data: PropTypes.shape({
    linkObj: PropTypes.string,
    openLinkInNewTab: PropTypes.bool,
    id: PropTypes.string,
    transform: PropTypes.shape({
      scale: PropTypes.number,
      rotation: PropTypes.number,
      offsetX: PropTypes.number,
      offsetY: PropTypes.number,
    }),
    icon: PropTypes.shape({
      size: PropTypes.string,
      color: PropTypes.string,
      class: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
  useTransforms: PropTypes.bool,
};

export default SectionMedia;
