import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import ButtonEditor from '../../../EditorPanel/ButtonEditor';
import SubLinks from './SubLinks';
import Image from '../../../Image';

import styles from './styles.module.css';

class NavLink extends React.Component {

  constructor(props) {

    super(props);

    this.hoverMenuLink = this.hoverMenuLink.bind(this);

  }

  hoverMenuLink(event, target) {

    if (event.type === 'mouseenter') {

      if (event.target.closest('.NavLink') && target !== this.props.showSubPageLink) {

        this.props.onMenuLinkClick(target);

      }

    } else if (event.type === 'mouseleave') {

      if (
        (!event.relatedTarget.closest('.NavLink') && !event.target.className.includes('arrow'))
      ) {

        this.props.onMenuLinkClick(target);

      }

    }

  }

  render() {

    const positionRelative = {
      position: 'relative',
    };

    const rawLink = this.props.link;
    let link;
    let targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';

    let button;
    if (rawLink.format === 'BUTTON') {

      button = (
        <ButtonEditor
          themeData={this.props.themeData}
          button="Button"
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path || ''}
          buttons={this.props.buttons}
        />
      );

    }

    let style = {
      ...positionRelative,
      color: this.props.color,
    };
    if (this.props.isBurger) {

      style = { ...style, fontSize: '1.25rem' };

    }

    const linkContent = (
      <React.Fragment>
        {
          rawLink.format === 'IMAGE' && rawLink.formatData && rawLink.formatData.id
          && (
            (
              <Image
                id={rawLink.formatData.id}
                alt={`Navbar_Image_${rawLink.formatData.id}`}
                imageClass="navbarImage"
                images={this.props.images}
              />
            )
          )
        }
        { button }
        {
          rawLink.format === 'ICON' && rawLink.formatData
          && (
            <i className={`entypo ${rawLink.formatData.class}`} style={{ fontSize: rawLink.formatData.size, color: rawLink.formatData.color }} aria-hidden />
          )
        }
        {
          rawLink.format === 'TEXT'
          && (
            (rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path))
            || ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name))
            || rawLink.name
          )
        }
      </React.Fragment>
    );

    if (rawLink.type === 'FILE') targetValue = '_blank';

    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE' || targetValue === '_blank') {

      let url = rawLink.path;
      if (rawLink.type === 'SECTION') url = `${rawLink.path}#${rawLink.id}`;
      link = (
        <a
          href={url}
          className={`${styles[`menu${rawLink.children && rawLink.children.length > 0 && !this.props.isBurger ? 'Main' : ''}Link`]} ${styles.Link}`}
          style={style}
          target={targetValue}
          rel="noopener noreferrer"
        >
          { linkContent }
        </a>
      );

    } else {

      link = (
        <Link
          to={rawLink.path || '/'}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.id } : undefined}
          className={`${styles[`menu${rawLink.children && rawLink.children.length > 0 && !this.props.isBurger ? 'Main' : ''}Link`]} ${styles.Link}`}
          style={style}
          activeStyle={rawLink.type !== 'SECTION' ? this.props.activeStyle : undefined}
          onClick={this.props.onLinkClick}
        >
          { linkContent }
        </Link>
      );

    }

    const fontSize = this.props.themeData.typography.navigation.fontSize === 'Small'
      ? '0.85rem'
      : this.props.themeData.typography.navigation.fontSize === 'Normal'
        ? '1rem'
        : '1.25rem';

    const themeNavigationStyle = {
      fontFamily: this.props.themeData.typography.navigation.name,
      fontWeight: this.props.themeData.typography.navigation.weight,
      lineHeight: this.props.themeData.typography.navigation.lineHeight,
      letterSpacing: this.props.themeData.typography.navigation.letterSpacing,
      fontSize,
    };

    let divider;
    if (this.props.divider && this.props.divider > 1) {

      let div;
      switch (this.props.divider) {

        case 2:
          div = <span style={themeNavigationStyle}>&bull;</span>;
          break;
        case 3:
          div = <span style={themeNavigationStyle}>&#xFF5C;</span>;
          break;
        default:
        // Nothing here

      }

      divider = (
        <div style={{ color: this.props.color, display: 'inline-flex', alignItems: 'center' }}>
          {div}
        </div>
      );

    }

    const element = (
      <React.Fragment>
        { this.props.additionalDivider && divider}
        <div
          className={`NavLink noEvents ${styles[`menuItem${rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 ? 'Sub' : ''}`]}`}
          key={`NavigationBar_${rawLink.id}`}
          onMouseEnter={!this.props.isBurger && rawLink.children && rawLink.children.length > 0
            ? e => this.hoverMenuLink(e, rawLink.id) : undefined}
          onMouseLeave={!this.props.isBurger && rawLink.children && rawLink.children.length > 0
            ? e => this.hoverMenuLink(e, rawLink.id) : undefined}
          style={rawLink.format === 'ICON'
            ? { marginLeft: '5px', marginRight: '5px' }
            : rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 && this.props.isBurger
              ? { justifyContent: 'center', flexWrap: 'wrap' }
              : undefined
          }
        >
          { rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 && this.props.isBurger ? <div style={{ width: '100%' }}>{link}</div> : link }
          {
            !this.props.isBurger && rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0
            && (
              <div
                className={styles.Link}
                style={{ display: 'inline-flex', alignItems: 'center' }}
                role="button"
                tabIndex="0"
                onKeyPress={() => this.props.onMenuLinkClick(rawLink.id)}
                onClick={() => this.props.onMenuLinkClick(rawLink.id)}
              >
                <span
                  style={{ ...themeNavigationStyle, ...{ color: this.props.color } }}
                  className="entypo icon-down-open"
                />
              </div>
            )
          }
          {
            (this.props.isBurger || this.props.showSubPageLink === rawLink.id)
            && rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0
            && (
              <SubLinks
                onLinkClick={this.props.onLinkClick}
                themeData={this.props.themeData}
                color={this.props.color}
                activeStyle={this.props.activeStyle}
                links={rawLink.children}
                isBurger={this.props.isBurger}
                subColor={this.props.subColor}
              />
            )
          }
        </div>
        { divider }
      </React.Fragment>
    );

    return element;

  }

}

NavLink.propTypes = {
  color: PropTypes.string,
  link: PropTypes.shape({}),
  onLinkClick: PropTypes.func,
  onMenuLinkClick: PropTypes.func,
  showSubPageLink: PropTypes.string,
};

export default NavLink;
